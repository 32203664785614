import React from 'react';
import { NavLink, withRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Restricted from '../../Utils/Restricted';
import { Container } from '../../../Container';

class CommonMenu extends React.Component {


    render() {
        const triggerText = '     Ajouter une tâche';
        const onSubmit = (event) => {
            event.preventDefault(event);
            console.log(event.target.type.value);
            console.log(event.target.site.value);
            console.log(event.target.date.value);
            console.log(event.target.ag.value);
            console.log(event.target.p.value);
            console.log(event.target.al.value);
        };
        return (
            <div>
                {/* <div  style={{backgroundColor:"#0000FF"}}> */}
                {/* </div> */}

                {/* <form action="" method="GET"  onSubmit="" >
						<button type="submit" className={"btn btn-primary btn-block mb-2"}> Ajouter une tache </button>
						</form> */}

                {/* <div className="pl-5"> <Container triggerText={triggerText} onSubmit={onSubmit} /></div>   */}

                <div className="row">

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-8">
                            <Restricted role={"manager"} >
                                 <Container triggerText={triggerText} onSubmit={onSubmit} />
                             </Restricted>
                            </div>
                            <div className="col-md-3"></div>
                        </div>

                    </div>

                    <div className="col-md-8">
                        <span className=''>


                            <ul className={"namespace-menu"}>

                                {/* <Container triggerText={triggerText} onSubmit={onSubmit} /> */}
                               {/*  <Restricted scope={"pm"} permission={"read_task"} role={"manager"} >
                                    <li><NavLink strict to="/dashboard">Tableau de bords </NavLink></li>
                                </Restricted> */}
                                {/* <Restricted scope={"pm"} ><li><NavLink to={"/delivery"}>Delivery</NavLink></li></Restricted> */}
                                <Restricted scope={"pm"} role={"manager"} ><li><NavLink to={"/taches"}>Tâches</NavLink></li></Restricted>
                                {/* <Restricted scope={"pm"} ><li><NavLink to={"/installation"}>Installation</NavLink></li></Restricted> */}
                                <Restricted scope={"pm"} role={"manager"}><li><NavLink to={"/items"}>Items</NavLink></li></Restricted>

                                {/*<Restricted role={"admin"} scope={"pm"} ><li><NavLink to={"/cm"}>CM</NavLink></li></Restricted>
                                <Restricted role={"manager"} scope={"gpm"} ><li><NavLink to={"/gpm"}>GPM</NavLink></li></Restricted>*/}

                                <Restricted scope={"pm"} role={"manager"} >
                                <li className={"has-children"}>
                                        <a href="#">Directions</a>
                                        <ul className={"main-submenu"}>

                                            <Restricted role={"manager"} >
                                                <li><NavLink to="/directions">Directions </NavLink></li>
                                            </Restricted>

                                            <Restricted role={"manager"} >
                                                <li><NavLink to="/structure">Structure</NavLink></li>
                                            </Restricted>

                                            {/* <Restricted role={"admin"} >
                                                <li><NavLink to="/sousstructure">Sous Structure </NavLink></li>
                                            </Restricted> */}

                                        </ul>
                                    </li>
                                    </Restricted>
                                <Restricted scope={"pm"} role={"manager"}><li><NavLink to={"/planning"}>Planning</NavLink></li></Restricted>
                                
                                <Restricted role={"manager"} >

                                    <li className={"has-children"}>
                                        <a href="#">Utilisateurs</a>
                                        <ul className={"main-submenu"}>

                                            <Restricted role={"manager"} >
                                                <li><NavLink to="/agents">Agents </NavLink></li>
                                            </Restricted>

                                            <Restricted role={"cluster_manager"} >
                                                <li><NavLink to="/managers">Managers</NavLink></li>
                                            </Restricted>

                                            <Restricted role={"admin"} >
                                                <li><NavLink to="/clustermanagers">Cluster Managers </NavLink></li>
                                            </Restricted>

                                        </ul>
                                    </li>
                                </Restricted>

                                {/* <Restricted scope={"pm"} ><li><NavLink to={"/task_list"}>Liste des taches </NavLink></li></Restricted> */}



                                {/*<Restricted role={"admin"} >
                    <li><NavLink to="/settings">Configuration </NavLink></li>
                </Restricted>*/}

                                {/*<Restricted role={"agent"} scope={"pm"}>
                    <li><NavLink to="/manuals">Fichiers & guides</NavLink></li>
                </Restricted>*/}
                                {/*<li><NavLink to="/manuals">Fichiers & guides</NavLink></li>*/}

                            </ul>


                            <Switch>

                                {/* <Route path={"/gpm"} strict component={() => <>
                    <ul className="header-menu">
                        {/* {role !== "agent" && (<li><NavLink strict to="/glm/dashboard">Tableau de bord </NavLink></li>)} */}
                                {/* <Restricted scope={"gpm"} permission={"read_task"} role={"manager"} >
                            <li><NavLink strict to="/gpm/dashboard">Tableau de bord </NavLink></li>
                        </Restricted>
                        <Restricted scope={"gpm"} permission={"read_task"} >
                            <li><NavLink to="/gpm/task">GPM </NavLink></li>
                        </Restricted>
                        <Restricted scope={"gpm"} permission={"read_site"} >
                            <li><NavLink to="/gpm/sites">Sites </NavLink></li>
                        </Restricted> */}


                                {/* {role !== "agent" && (<li><NavLink to="/gpm/anomalies">Anomalies </NavLink></li>)} */}
                                {/* {(role === "admin" || role === "cluster_manager") && (<li><NavLink to="/gpm/planning">Planning </NavLink></li>)} */}
                                {/* {role !== "agent" && (<li><NavLink to="/agents">Agents </NavLink></li>)} */}
                                {/* {(role === "admin" || role === "cluster_manager") && (<li><NavLink to="/managers">Managers</NavLink></li>)} */}
                                {/* {role === "admin" && (<li><NavLink to="/clustermanagers">Cluster Managers </NavLink></li>)} */}
                                {/* {role === "admin" && (<li><NavLink to="/settings">Configuration </NavLink></li>)} */}
                                {/* </ul>
                </>} /> */}

                                {/* <Route path={"/cm"} strict component={() => <>
                    <ul className="header-menu">
                       
                        <Restricted role={"admin"}>
                            <li><NavLink to="/cm/task">Tasks </NavLink></li>
                        </Restricted>
                        <Restricted role={"admin"}>
                            <li><NavLink to="/cm/sites">Sites </NavLink></li>
                        </Restricted>

                    </ul>
                </>} /> */}


                                {/* <Route path={"/delivery"} strict component={() => <>
                    <ul className="header-menu"> */}
                                {/*  <Restricted scope={"pm"} permission={"read_task"} role={"manager"} >
                            <li><NavLink strict to="/dashboard">Tableau de bord </NavLink></li>
                        </Restricted> */}
                                {/* <Restricted scope={"pm"} permission={"read_task"} role={"agent"} >
                            <li><NavLink to="/delivery/task">Tasks </NavLink></li>
                        </Restricted>
                        <Restricted scope={"pm"} permission={"read_site"} role={"agent"} >
                            <li><NavLink to="/delivery/sites">Sites </NavLink></li>
                        </Restricted> */}
                                {/*  <Restricted scope={"pm"} permission={"read_site"} role={"manager"} >
                            <li><NavLink to="/delivery/anomalies">Anomalies </NavLink></li>
                        </Restricted>
                        <Restricted scope={"pm"} permission={"read_site_plan"} role={"cluster_manager"} >
                            <li><NavLink to="/delivery/planning">Planning </NavLink></li>
                        </Restricted> */}

                                {/* </ul>
                </>} /> */}

                                {/* <Route path={"/ssr"} strict component={() => <>
                    <ul className="header-menu"> */}
                                {/* {role !== "agent" && (<li><NavLink strict to="/glm/dashboard">Tableau de bord </NavLink></li>)} */}
                                {/* <Restricted scope={"pm"} permission={"read_task"} role={"agent"}>
                            <li><NavLink to="/ssr/task">Tasks </NavLink></li>
                        </Restricted>
                        <Restricted scope={"pm"} permission={"read_task"} role={"agent"}>
                            <li><NavLink to="/ssr/sites">Sites </NavLink></li>
                        </Restricted>

                    </ul>
                </>} /> */}


                            </Switch>




                        </span>
                    </div>
                </div>



            </div>

        );
    }

}

const mapState = state => {
    return {
        session: state.session
    }
}
export default connect(mapState, null, null, { pure: false })(withRouter(CommonMenu));
