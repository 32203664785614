import React from "react"
import Select from "react-select";
import { connect } from "react-redux";
import CoSiteList from "./CoSiteList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faMap } from "@fortawesome/free-solid-svg-icons";
import api from "../../../Utils/Api";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from "leaflet";
//import 'leaflet/dist/leaflet.css';
import "leaflet/dist/images/marker-shadow.png";
import CoSiteMapGeoManZone from "./CoSiteMapGeoManZone";
import CoSiteColor from "./CoSiteColor";

class General extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            color: '#7bfc03',
            data: null, 
            zoom: null,
            center: null, 
            image: null,
        };

        this.setData= this.setData.bind(this)
        this.setColor = this.setColor.bind(this)
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    setColor = (color) => {
        this.setState({
          color: color.hex
        })
    }

    setData = (data , zoom, center,image) => {
        console.log('data')
        this.setState({
          data: data,
          zoom: zoom,
          center: center,
          image:image
        })
    }
    
    sendData = () => {
        if(!this.state.data){
          return
        }
        // let data = {
        //     "type":"FeatureCollection",
        //     "features":this.state.data
        // }
        let data = {
          layer: this.state.data,
          zoom: this.state.zoom,
          center: this.state.center,
          image: this.state.image
        }
        api.post('/api/zone/setData/'+this.props.site.site_id, data).then(res =>  {
            if(res.data){
                console.log('res'+res.data.req)
                this.setState({show: false})
            }
        })
    }
    
    render() {

        const site = this.props.site || {};
        const position = [51.505, -0.09]
        // const outer = [
        //     [50.505, -29.09],
        //     [52.505, 29.09],
        //   ]

        console.log(this.props);

        return (<>
        
        <div className={"row"}>

            <div className={"col-md-5"}>
                <h3>
                    Info

                    {/* <button 
                        type="button" 
                        onClick={() => this.setState({show: !this.state.show})} 
                        className={"btn btn-sm btn-primary float-right"}
                        >
                        Map &nbsp;
                        <FontAwesomeIcon icon={faMap} />{" "}
                    </button> */}
                </h3>
                
                
                <div className={"form-group"}>
                    <label>Direction Code </label>
                    <input type={"text"} className={"form-control"} defaultValue={site.site_code} />
                </div>

                {/* <div className={"form-group"}>
                    <label>Code Facturation Delivery</label>
                    <input type={"text"} className={"form-control"} defaultValue={site.site_code_facture} />
                </div> */}


                <div className={"form-group"}>
                    <label>Nom</label>
                    <input type={"text"} className={"form-control"} defaultValue={site.site_name} />
                </div>
                {/* <div className={"form-group"}>
                    <label>DOT</label>
                    <input type={"text"} className={"form-control"} defaultValue={site.site_dot} />
                </div>
                <div className={"form-group"}>
                    <label>CMP</label>
                    <input type={"text"} className={"form-control"} defaultValue={site.site_cmp} />
                </div>
                <div className={"form-group"}>
                    <label>Type OLT</label>
                    <input type={"text"} className={"form-control"} defaultValue={site.site_olt} />
                </div> */}

                <div className={"form-group"}>
                    <label>Wilaya</label>
                    <Select 
                        options={this.props.filters.wilayas}
                        defaultValue={{label: site.site_wilaya, value: site.site_wilaya}}
                        classNamePrefix={"custom-select"}
                    />
                </div>

                <div className={"form-group"}>
                    <label>Address</label>
                    <input type={"text"} className={"form-control"} defaultValue={site.site_address} />
                </div>


                <div className={"form-group"}>
                    <label>Owner</label>

                    <input type={"text"} className={"form-control"} defaultValue={site.site_owner} />
                  {/*   <Select 
                        options={this.props.filters.site_owners.filter(item => item.value !== "outsource" && item.label !== "All")}
                        defaultValue={{label: site.site_owner, value: site.site_owner}}
                        classNamePrefix={"custom-select"}
                    /> */}

                </div>

                <div className={"form-group"}>
                    <label>Ingénieur responsable</label>
                    <Select 
                        options={this.props.filters.agents}
                        defaultValue={{label: site.site_owner_agent, value: site.site_owner_agent_id}}
                        classNamePrefix={"custom-select"}
                    />
                </div>

                <div className={"clearfix mt-10"}></div>

                

            </div>


            <div className={"col-md-7 "} >
                

                {site.site_id && (<CoSiteList siteid={site.site_id} site={site} />)}

                <div className={"clearfix my-4"}></div>

                {/* <h3>Notes</h3>
                <div className={"ctable"}>


                    <div className={"row head-titles ctable-row"}>
                        <div className={"col-md-8"}>
                                Note
                        </div>
                        <div className={"col-md-4"}>
                                Auteur
                        </div>
                    </div>
                    
                    {site.notes && site.notes.map(note => (
                        <div className={"row ctable-row"}>
                                <div className={"col-md-8"}>
                                        {note.content}
                                </div>
                                <div className={"col-md-4"}>
                                        {note.created_at}<br />
                                        {note.created_by}
                                </div>
                        </div>
                    ))}

                </div> */}
                
                {/* <div  className="map" >

                    <MapContainer center={[36.000, 2.09]} zoom={12} scrollWheelZoom={true} className="map"  >
                    
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[36.000, 2.09]}>
                        
                        </Marker>
                    </MapContainer>
           
                </div> */}
                    
               
                                
            </div>
            {/* <div  style={{width: "100%", overflow:"overlay"}}>
                <div className="mapContainer">
                    <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
                        <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={position}>
                        <Popup>
                            A pretty CSS3 popup. <br /> Easily customizable.
                        </Popup>
                        </Marker>
                    </MapContainer>

                </div>
            </div> */}
            


            <div className={"ui-overlay" + (this.state.show ? " open" : "")}>
                <div className={"ui-overlay-content anomalie-history col-md-7"}>
                    {/* <CoSiteRowDetails item={this.props.item} file={null} setData ={this.setData}/> */}
                    <CoSiteMapGeoManZone id={site.site_id} file={null} color={this.state.color} setData ={this.setData}/>
                    <div className=" d-flex justify-content-center">
                        <CoSiteColor
                            id = {site.site_id}
                            color= {this.state.color}
                            setColor={this.setColor} 
                        />
                    </div>
                    
                    <div className="mb-2"></div>
                    <button className="btn btn-danger" onClick={() => this.setState({show:false})} >Annuler</button>
                    { this.state.data ? <button className="btn btn-success" onClick={() => this.sendData()}>Sauvegarder</button>
                    : 
                    <button className="btn btn-success" type="button" disabled>Sauveggarder</button>
                    }
                </div>
            </div>
        </div>
        </>)
    }
}

export default connect(state => ({filters: state.filters }) )(General);